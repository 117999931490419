export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.client) {
    const protectedRoutes = ["/trial", "/register", "/developer-trial", "/referrals","/get-a-demo","/talk-to-an-expert","/become-a-partner"];
    const protectRoutes = protectedRoutes.includes(from.fullPath);
    const config = useRuntimeConfig().public;
    const { setItem, getItem } = useLocalStorage();

    const kudoGeo = getItem("kudogeo");

    if(!protectRoutes) return true;

    const blockListCountry = (config.FILTER_COUNTRY || "").split(",");
    const blockListIP = (config.FILTER_IP || "").split(",");
    const whiteListIP = (config.FILTER_IP_WHITE || "").split(",");
    let blackListed = null;
    if (!kudoGeo) {
      const response = await fetch(`/.netlify/functions/info`);

      const result = await response.json();

      if (result) {
        setItem("kudogeo", {
          data: result,
          country_code: result.country_code2,
          ip: result.ip,
        });

        if (whiteListIP.includes(result.ip)) {
          // return false;
        }

        if (blockListCountry.includes(result.country_code2) || blockListIP.includes(result.ip)) {
          if (protectRoutes) return navigateTo("/unavailable");
        }
      }

      if (blackListed) {
        if (protectRoutes) return navigateTo("/unavailable");
      }
    } else {
      if (whiteListIP.includes(kudoGeo.ip)) {
        // return false;
      }

      if (blockListCountry.includes(kudoGeo.country_code) || blockListIP.includes(kudoGeo.ip)) {
        if (protectRoutes) return navigateTo("/unavailable");
      }
    }

    return true;
  }
});
